<template>
	<div class="page">
		<div class="container">
      <div class="bg-img">
        <img :src="style.memberBgImg1" alt="" class="img">
      </div>
      <div class="logo" >
      	<img src="https://exhoss37.szcreativeweek.com/common/applet/logo.png" alt="" class="fill">
      </div>
			<div class="title">Your request has <br> been submitted</div>
			<div class="content">Your submission has been received and currently being pended.</div>
		</div>
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level" language="en" color="#333"></bottom>
		</div>
	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import waitingMixin from '@/view/process/mixin/waiting.js';

	export default {
		mixins: [commonMixin, waitingMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
		},
		methods: {

		},
	}
</script>

<style lang="less" scoped>
// PC
@media screen and (min-width: 751px) {
  .page {
    background-color: #fff;
  }
  
  .logo {
  	width: 200px;
    max-width: 400Px;
  	margin: 10vh auto;
  }
  .container {
    position: relative;
    
    .bg-img {
      position: absolute;
      top: 5vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      opacity: .1;
    }
    .img {
      width: 313px;
      max-width: 626Px;
      // height: 350px;
      height: 700Px;
    }
    
    .title {
      margin: 6vh auto ;
      text-align: center;
      color: #333;
      font-family: Source Han Sans CN;
      font-size: 48Px;
      line-height: 72Px;
      text-align: center;
    }
  }
  
  .content {
    width: 300px;
    max-width: 600Px;
    margin: 20px auto;
    color: #333;
    font-family: Source Han Sans CN;
    font-weight: 400;
    height: 50Px;
    line-height: 50Px;
    font-size: 16Px;
    text-align: center;
  }
}
// 移动
@media screen and (max-width: 750px) {
  .page {
    background-color: #fff;
  }
  
	.logo {
		width: 200px;
		margin: 10vh auto;
	}
  .container {
    position: relative;
    
    .bg-img {
      position: absolute;
      top: 5vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      opacity: .1;
    }
    .img {
      width: 313px;
      height: 350px;
    }
    
    .title {
      margin: 6vh auto ;
      text-align: center;
      color: #333;
      font-family: Source Han Sans CN;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 2rpx;
    }
  }

	.content {
    width: 300px;
    margin: 20px auto;
    color: #333;
    font-family: Source Han Sans CN;
    font-weight: 400;
    height: 25px;
    line-height: 25px;
    font-size: 16Px;
    text-align: left;
	}
}
</style>
